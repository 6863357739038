$('.header_menu_btn').click(function () {
    $('.header_menu').toggleClass('open');
    $(this).toggleClass('open');
});
$('.category-page_sidebar_title').click(function () {
    $('.category-page_sidebar_list').slideToggle();
    $(this).toggleClass('open');
});
$(window).scroll(function(){
    if($(window).scrollTop() > 50){
        $('header').addClass('shadow');
    } else {
        $('header').removeClass('shadow');
    }
});
$('.main-screen_slider').slick({
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
});
$("header .menu").on("click",'a', function(e){
    var anchor = $(this);
    $('html, body').stop().animate({
        scrollTop: $(anchor.attr('href')).offset().top -125
    }, 777);
    e.preventDefault();
    return false;
});
